<template>
  <InvitePage :title="title">
    <InlineLoader v-if="isLoading" size="large" />

    <div v-else>
      <Message v-if="invitationNotFound" type="secondary" :message="$t('invite.noInviteFound')" />
      <Message v-else-if="hasAlreadyAnsweredInvite" type="secondary" :message="$t('invite.alreadyAnswered')" />
      <Message v-else-if="hasAccepted" type="secondary" :message="$t('invite.userAcceptedHunt')" />
      <Message v-else-if="hasDeclined" type="secondary" :message="$t('invite.userDeclinedHunt')" />

      <div v-else>
        <InviteHeader
          :where="invite.huntName"
          :starts-at="invite.startsAt"
          :ends-at="invite.endsAt">
        </InviteHeader>

        <Divider />

        <div v-if="invite.message.length > 0">
          <Paragraph class="content text-is-preformatted" v-html="invite.message" />
          <Divider />
        </div>

        <Heading size="5" :text="$t('general.invited')" />

        <InviteMember
          v-for="participant in invite.participants"
          :key="participant.userId"
          :user-id="participant.id"
          :name="participant.name"
          :badge="participantRoleBadge(participant)">
        </InviteMember>

        <Divider />

        <ButtonGroup>
          <InviteAcceptButton
            :loading="isAccepting"
            :disabled="isDeclining"
            @click="respond(true)">
          </InviteAcceptButton>

          <InviteDeclineButton
            :loading="isDeclining"
            :disabled="isAccepting"
            @click="respond(false)">
          </InviteDeclineButton>
        </ButtonGroup>
      </div>
    </div>
  </InvitePage>
</template>

<script>
import { mapGetters } from 'vuex'

import InvitePage from './InvitePage.vue'
import InviteHeader from './InviteHeader.vue'
import InviteMember from './InviteMember.vue'
import InviteAcceptButton from './InviteAcceptButton.vue'
import InviteDeclineButton from './InviteDeclineButton.vue'

import {
  STATUS_LOADING,
  STATUS_ALREADY_ANSWERED,
  STATUS_ACCEPTED,
  STATUS_DECLINED,
  STATUS_ACCEPTING,
  STATUS_DECLINING,
  STATUS_NOT_FOUND
} from '../store/inviteStatus'

export default {
  components: {
    InvitePage,
    InviteHeader,
    InviteMember,
    InviteAcceptButton,
    InviteDeclineButton
  },

  computed: {
    ...mapGetters({
      invite: 'invites/hunt/getInvite',
      status: 'invites/hunt/getStatus'
    }),

    title () {
      return this.$i18n.t('invite.title', { name: this.$i18n.t('hunt.title').toLocaleLowerCase() })
    },

    isLoading () {
      return this.status === STATUS_LOADING
    },

    invitationNotFound () {
      return this.status === STATUS_NOT_FOUND
    },

    hasAccepted () {
      return this.status === STATUS_ACCEPTED
    },

    hasDeclined () {
      return this.status === STATUS_DECLINED
    },

    hasAlreadyAnsweredInvite () {
      return this.status === STATUS_ALREADY_ANSWERED
    },

    isAccepting () {
      return this.status === STATUS_ACCEPTING
    },

    isDeclining () {
      return this.status === STATUS_DECLINING
    }
  },

  created () {
    this.$store.dispatch('invites/hunt/readByToken', this.$route.params.token)
  },

  methods: {
    async respond (accept) {
      try {
        await this.$store.dispatch('invites/hunt/answerInvite', accept)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('invite.unknownErrorMessage'))
      }
    },

    participantRoleBadge (participant) {
      const roles = {
        leader: this.$i18n.t('hunt.huntLeader.title'),
        aftersearch: this.$i18n.t('hunt.trackingHunter.title')
      }

      return roles[participant.role] !== undefined ? roles[participant.role] : ''
    }
  }
}
</script>
