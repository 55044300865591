<template>
  <Button
    type="success"
    :outlined="true"
    :loading="loading"
    :disabled="disabled"
    icon="icon-check"
    :title="$t('invite.accept')"
    @click="$emit('click')">
  </Button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
