<template>
  <div class="level is-mobile">
    <div lass="level-left">
      <ProfilePicture :userId="Number(userId)" size="small" round />

      <span class="push-left-xs">{{ name }}</span>

      <Badge
        v-if="badge.length > 0"
        :type="badgeType"
        class="push-left-xs"
        :text="badge">
      </Badge>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    },

    badge: {
      type: String,
      default: ''
    },

    badgeType: {
      type: String,
      default: ''
    }
  }
}
</script>
