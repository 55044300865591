<template>
  <div class="level">
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">{{ $t('general.where') }}</p>
        <p class="title is-5">{{ where }}</p>
      </div>
    </div>

    <div class="level-item has-text-centered">
      <div>
        <p class="heading">{{ $t('general.begins') }}</p>
        <p class="title is-5">{{ startTime }}</p>
      </div>
    </div>

    <div class="level-item has-text-centered">
      <div>
        <p class="heading">{{ $t('general.ends') }}</p>
        <p class="title is-5">{{ endTime }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    where: {
      type: String,
      default: ''
    },

    startsAt: {
      type: Date,
      required: true
    },

    endsAt: {
      type: Date,
      required: true
    }
  },

  computed: {
    startTime () {
      return this.$dayjs(this.startsAt).format('D MMM, HH:mm')
    },

    endTime () {
      const isSameDay = this.$dayjs(this.startsAt).isSame(this.endsAt, 'day')
      const format = isSameDay ? 'HH:mm' : 'D MMM, HH:mm'

      return this.$dayjs(this.endsAt).format(format)
    }
  }
}
</script>
