<template>
  <div class="invite-page">
    <a class="delete is-large" @click="close"></a>

    <div class="inner" :class="'inner-' + type">
      <Logo color="black" />
      <Heading v-if="title.length > 0" size="4" :text="title" />

      <Divider />

      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'default'
    }
  },

  methods: {
    close () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.invite-page {
  background: white;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow-y: auto;

  // padding: 2.5rem;
  transition: all 0.25s ease-out;

  .inner {
    padding: 2.5rem;
    width: 90%;
    margin: auto;
  }

  .inner-default {
    max-width: 800px;
  }

  .inner-form {
    max-width: 610px;
  }

  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .delete {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    z-index: 2;
  }
}
</style>
